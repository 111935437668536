import styled from "@emotion/styled"
import { Box } from "theme-ui"

export const HorizontalHairline = styled(Box)`
  height: 1px;
  width: 100%;
  border-top: 1px solid ${(props) => props.theme.colors.lightGreen};
`
export const VerticalHairline = styled(Box)`
  width: 1px;
  border-right: 1px solid ${(props) => props.theme.colors.darkGreen};
`
