import styled from "@emotion/styled"
import React, { useContext } from "react"
import { Box, Flex, Grid, Image } from "theme-ui"
import StoreContext from "../../context/store-context"
import { formatPrices } from "../../util/prices"
import Text from "../base/text/text"

const CartItem = styled(Flex)``

const CartImage = styled(Image)`
  width: 100px;
  height: 100%;
  object-fit: contain;
`

const CartInfo = styled(Flex)`
  flex-direction: column;
  flex: 1;
  flex-wrap: wrap;
`

const Container = styled(Flex)`
  flex-direction: column;
`

const CategoryPoint = styled(Text)`
  color: ${(props) => props.theme.colors.lightGreen};
`

const CartItemList = ({ items, sx }) => {
  const { cart } = useContext(StoreContext)
  const finalItems = items || cart.items
  return (
    <Container sx={sx}>
      {finalItems &&
        finalItems.map((item, i) => {
          const price = item.unit_price
            ? formatPrices(cart, item.unit_price)
            : ""
          const { title: variantTitle, product } = item.variant
          let parts = variantTitle.split("-")
          // const size = parts.pop()

          const name = item.title.split(" - ")
          const color = name.pop()

          const size = item.metadata?.size || ""

          return (
            <CartItem
              key={i}
              my={2}
              sx={{ fontSize: [2, 2, 3], fontWeight: "normal" }}
            >
              <CartImage src={item.thumbnail} alt="" />
              <CartInfo px={2} sx={{ fontSize: ["12px", "12px"] }}>
                <Text px={2} pb={1} pt={0}>
                  {name.join(" - ")}
                </Text>
                <Grid sx={{ rowGap: 0 }} px={2} columns={[2, "1fr, 1fr"]}>
                  {color && (
                    <>
                      <Box py={0}>
                        <CategoryPoint py={0}>Color:</CategoryPoint>
                      </Box>
                      <Box py={0}>
                        <Text py={0}>{color}</Text>
                      </Box>
                    </>
                  )}
                  {size && variantTitle !== product?.title && !item.is_giftcard && (
                    <>
                      <Box py={0}>
                        <CategoryPoint py={0}>Size:</CategoryPoint>
                      </Box>
                      <Box py={0}>
                        <Text py={0}>{size.toUpperCase()}</Text>
                      </Box>
                    </>
                  )}
                  <Box py={0}>
                    <CategoryPoint py={0}>Quantity:</CategoryPoint>
                  </Box>
                  <Box py={0}>
                    <Text py={0}>{item.quantity}</Text>
                  </Box>
                  <Box py={0}>
                    <CategoryPoint>Price: </CategoryPoint>
                  </Box>
                  <Box py={0}>
                    <Text>{price}</Text>
                  </Box>
                </Grid>
              </CartInfo>
            </CartItem>
          )
        })}
    </Container>
  )
}

export default CartItemList
