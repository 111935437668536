import qs from "query-string"
import React, { useContext, useEffect, useState } from "react"
import { Box, Flex, Text } from "theme-ui"
import CartItemList from "../components/cart/cart-item-list"
import CartTotals from "../components/cart/cart-totals"
import StoreContext from "../context/store-context"
import { createClient } from "../util/client"

const client = createClient()
const CheckoutDone = ({ location }) => {
  const [order, setOrder] = useState()
  const { clearCart } = useContext(StoreContext)
  const [fetchingOrder, setFetchingOrder] = useState(true)

  useEffect(() => {
    const fetchOrder = async () => {
      const { o } = qs.parse(location.search)

      if (o) {
        try {
          const data = await client.orders.retrieve(o)
          clearCart()
          setOrder(data?.order)

          // Store the order token in localstorage we use this to
          // prevent double analytics calls
          const tokens = JSON.parse(localStorage.getItem("ots")) || []
          if (!tokens.includes(o)) {
            tokens.push(o)
            //TODO: trackPurchase(data.order)
          }
          localStorage.setItem("ots", JSON.stringify(tokens))

          if (data.order && data.order.customerId) {
            window.analytics.identify(
              data.order.customerId,
              {
                email: data.order.email,
              },
              {
                integrations: {
                  MailChimp: false,
                },
              }
            )
          }
        } catch (error) {
          console.log(error)
        }

        setFetchingOrder(false)
      }
    }

    fetchOrder()
  }, [location.search])

  return (
    <Flex sx={{ justifyContent: "center", alignItems: "center" }} p={3}>
      {fetchingOrder ? (
        <Text>Loading</Text>
      ) : (
        <Flex sx={{ flexDirection: "column" }}>
          <Box mb={3}>
            <Text as="h3" mb={3}>
              Thanks for your order, {order.shipping_address?.first_name}
            </Text>
            <Text as="p">
              An order confirmation will reach your inbox shortly.
            </Text>
            <Text as="p">Your order no. is {order.display_id}.</Text>
          </Box>
          <CartItemList items={order.items} />
          <CartTotals order={order} isShippingSet={true} />
        </Flex>
      )}
    </Flex>
  )
}

export default CheckoutDone
